import React from 'react';

import { ApolloError } from '@apollo/client';
import { AdvertiserReportingTableNoResults, TextWeight } from 'src/common/__generated__/api/types';
import { UitkCard } from 'uitk-react-cards';
import { UitkIcon, UitkIconSize } from 'uitk-react-icons';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkLoader } from 'uitk-react-loader';
import { UitkLineSkeleton } from 'uitk-react-skeleton';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkText } from 'uitk-react-text';

import { ErrorCard } from '../common/error-card';

export const TableLoading: React.FC<{
  text: string;
  weight: TextWeight;
}> = (props) => {
  return (
    <UitkCard>
      <UitkSpacing margin={{ blockstart: 'three', inlinestart: 'six' }}>
        <UitkLineSkeleton />
      </UitkSpacing>
      <UitkSpacing margin={{ blockstart: 'two', inlinestart: 'six' }}>
        <UitkLineSkeleton type="secondary" />
      </UitkSpacing>
      <UitkLayoutFlex alignContent="center" justifyContent="center">
        <UitkSpacing padding={{ block: 'sixteen' }} margin={{ block: 'eight' }}>
          <UitkText align="center">
            <UitkLoader />
            <UitkSpacing padding={{ blockstart: 'four' }}>
              <UitkText weight={props.weight.toLowerCase() as 'bold' | 'regular' | 'medium'} size={500}>
                {props.text}
              </UitkText>
            </UitkSpacing>
          </UitkText>
        </UitkSpacing>
      </UitkLayoutFlex>
    </UitkCard>
  );
};

export const TableError: React.FC<{
  dataTableError: ApolloError;
  reloadTable: () => void;
}> = (props) => {
  const { dataTableError } = props;
  const errorMessage =
    'classification' in (dataTableError.graphQLErrors[0]?.extensions || {}) &&
    dataTableError.graphQLErrors[0]?.extensions.classification !== 'DataFetchingException'
      ? `[Error ${dataTableError.message.slice(-3)}]`
      : '[Error]';
  return (
    <UitkLayoutFlex alignContent="center" justifyContent="center">
      <UitkSpacing margin={{ blockstart: 'eight', blockend: 'sixteen' }}>
        <UitkText>
          <ErrorCard error={dataTableError} message={errorMessage} theme="primary" reloadClick={props.reloadTable} />
        </UitkText>
      </UitkSpacing>
    </UitkLayoutFlex>
  );
};

export const NoResults: React.FC<{
  noResults: AdvertiserReportingTableNoResults;
}> = (props) => {
  return (
    <UitkSpacing
      padding={{ blockstart: 'sixteen', blockend: 'sixteen' }}
      margin={{ blockstart: 'eight', blockend: 'sixteen' }}
    >
      <UitkLayoutFlex alignContent="center" justifyContent="center">
        <UitkText align="center">
          <UitkSpacing
            margin={{
              blockend: 'four',
            }}
          >
            <UitkIcon name={props.noResults.noResultsIcon.token} size={UitkIconSize.XLARGE} />
          </UitkSpacing>
          <UitkSpacing
            margin={{
              blockend: 'two',
            }}
          >
            <UitkText
              size={500}
              weight={props.noResults.primaryText.weight.toLowerCase() as 'bold' | 'regular' | 'medium'}
            >
              {props.noResults.primaryText.text}
            </UitkText>
          </UitkSpacing>
          <UitkSpacing margin="two">
            <UitkLayoutFlexItem maxWidth={230}>
              <UitkText size={300}>{props.noResults.secondaryText.text}</UitkText>
            </UitkLayoutFlexItem>
          </UitkSpacing>
        </UitkText>
      </UitkLayoutFlex>
    </UitkSpacing>
  );
};
