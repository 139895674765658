import React from 'react';

import { NavLink, useLocation } from 'react-router-dom-v5-compat';
import { UitkIcon } from 'uitk-react-icons';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkLink } from 'uitk-react-link';
import { UitkListItem } from 'uitk-react-list';
import { UitkSpacingHr } from 'uitk-react-spacing';
import { UitkText } from 'uitk-react-text';

interface INavMenuItem {
  titleKey?: string;
  href: string;
  relatedPages: RegExp;
  icon: string;
}

interface INavItemProps {
  item: INavMenuItem;
  index: number;
  closeNavIfMobile: () => void;
}

const NavItem: React.FC<INavItemProps> = (props: INavItemProps) => {
  const { item, closeNavIfMobile } = props;
  const location = useLocation();

  const hashMap = new Map();
  hashMap.set('Campaign', 'work');
  hashMap.set('AdGroup', 'view_grid_large');

  let isActive = false;
  if (location.pathname === item.href) {
    isActive = true;
  } else if (item.relatedPages.test(location.pathname)) {
    isActive = true;
  }
  const linkClassName = isActive ? 'nav-link-active nav-link' : 'nav-link';
  const listItemClassName = isActive ? 'nav-item nav-item-active' : 'nav-item';

  return (
    <>
      <UitkListItem className={listItemClassName} key={item.titleKey}>
        <UitkLink className={linkClassName}>
          <NavLink to={item.href} onClick={closeNavIfMobile}>
            <UitkLayoutFlex space="two">
              {item.icon != '' && (
                <UitkLayoutFlexItem>
                  <UitkIcon name={item.icon} />
                </UitkLayoutFlexItem>
              )}
              <UitkLayoutFlexItem>
                <UitkText size={300} spacing={item.icon != '' ? 'one' : undefined}>
                  {hashMap.has(item.titleKey) && (
                    <UitkIcon name={hashMap.get(item.titleKey)} className={item.icon != '' ? 'nav-icon-style' : ''} />
                  )}
                  <span className="item-text">
                    {/* <LocalizedText message={item.titleKey} /> */}
                    {item.titleKey}
                  </span>
                </UitkText>
              </UitkLayoutFlexItem>
            </UitkLayoutFlex>
          </NavLink>
        </UitkLink>
      </UitkListItem>
      <UitkListItem>{item.titleKey === 'Campaign' && <UitkSpacingHr />}</UitkListItem>
    </>
  );
};

export { NavItem, INavMenuItem };
