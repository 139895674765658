import * as React from 'react';

export enum ISideSheetName {
  HOW_CAMPAIGNS_WORK,
  HOW_AD_GROUPS_WORK,
  HOW_BUDGETING_WORKS,
  HOW_CAMPAIGN_GOALS_WORK,
  PARTNER_ACCOUNT_INFO,
  PAYMENT_PROFILE_INFO,
}

const SideInfoSheetContext = React.createContext(null);

const SideInfoSheetProvider = ({ children }) => {
  const sideSheetTriggerRef = React.useRef();
  const [showSideSheet, setShowSideSheet] = React.useState(false);
  const [sheetName, setSheetName] = React.useState<ISideSheetName | null>(null);

  return (
    <SideInfoSheetContext.Provider
      value={{ showSideSheet, setShowSideSheet, setSheetName, sheetName, sideSheetTriggerRef }}
    >
      {children}
    </SideInfoSheetContext.Provider>
  );
};

const useSideInfoSheetContext = () => {
  const context = React.useContext(SideInfoSheetContext);
  if (!context) {
    throw new Error('useSideInfoSheet must be used within a SideInfoSheetProvider');
  }
  return context;
};

export { SideInfoSheetProvider, useSideInfoSheetContext };
