import React from 'react';

import { UitkPrimaryButton } from 'uitk-react-button';
import { UitkCard, UitkCardBackgroundTheme } from 'uitk-react-cards';
import { UitkIcon } from 'uitk-react-icons';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkHeading, UitkText } from 'uitk-react-text';

export const ErrorCard: React.FC<{
  error: Error;
  theme: UitkCardBackgroundTheme;
  message?: string;
  reloadClick?: () => void;
}> = (props) => {
  const { error, message, theme } = props;
  return (
    <UitkCard backgroundTheme={theme}>
      <UitkSpacing margin={{ blockstart: 'twelve', blockend: 'three' }} padding={{ inline: 'six' }}>
        {message ? (
          <UitkHeading tag="h6" size={6}>
            {message}
          </UitkHeading>
        ) : (
          <>
            {error.message && (
              <UitkHeading tag="h6" size={6}>
                [{error?.message}]
              </UitkHeading>
            )}
          </>
        )}
      </UitkSpacing>
      <UitkSpacing padding={{ inline: 'six' }}>
        <UitkText>
          An error has occured
          <br />
          Refresh the page or try again later
        </UitkText>
      </UitkSpacing>
      <UitkSpacing margin={{ inlinestart: 'six', block: 'three' }}>
        <UitkPrimaryButton
          type="button"
          onClick={props.reloadClick ? () => props.reloadClick() : () => window.location.reload()}
        >
          Refresh <UitkIcon name="refresh" />
        </UitkPrimaryButton>
      </UitkSpacing>
    </UitkCard>
  );
};
