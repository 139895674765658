import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { UitkButton, UitkPrimaryButton } from 'uitk-react-button';
import { UitkCheckbox } from 'uitk-react-checkbox';
import { UitkDialog, UitkDialogContent } from 'uitk-react-dialog';
import { UitkHeading, UitkParagraph } from 'uitk-react-text';

import {
  fetchLocalStorageTermsAndConditionsAccepted,
  fetchTermsConditionsMetadata,
} from '../../utils/terms-conditions-util';

const TermsAndConditions: React.FC<{ onAccept: () => void }> = ({ onAccept }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [termsConditionsFileUrl, setTermsConditionsFileUrl] = useState('');
  const [termsConditionsVersion, setTermsConditionsVersion] = useState('');
  const history = useHistory();

  useEffect(() => {
    const getMetadata = async () => {
      try {
        const data = await fetchTermsConditionsMetadata();
        setTermsConditionsVersion(data.version);
        setTermsConditionsFileUrl(data.fileUrl);
        const storedVersion = fetchLocalStorageTermsAndConditionsAccepted();
        if (storedVersion !== data.version) {
          setShowDialog(true);
        }
      } catch (error) {
        console.error('Error fetching metadata:', error);
      }
    };

    getMetadata();
  }, []);

  const handleLogout = () => {
    history.push('/logout');
  };

  const handleAccept = async () => {
    try {
      await axios.post('/api/accept-terms-conditions', {});
      localStorage.setItem('capTermsAndConditionsAccepted', termsConditionsVersion);
      onAccept();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  if (!showDialog) return null;

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        background: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
      }}
    >
      <UitkDialog sheetType="centered" heightAuto dialogShow closeCallback={() => {}}>
        <UitkDialogContent>
          <div className="tc-dialog-content" style={{ overflow: 'hidden', width: '100%' }}>
            <UitkHeading>We have updated our Terms and Conditions</UitkHeading>
            <UitkParagraph>
              <br />
              Please review and accept our{' '}
              <a style={{ color: 'black' }} target="_blank" href={termsConditionsFileUrl} rel="noreferrer">
                <span style={{ textDecoration: 'underline' }}>Terms and Conditions</span>
              </a>{' '}
              for all your eligible accounts.
            </UitkParagraph>
            <br />
            <div style={{ marginRight: '5px', display: 'inline-flex', alignItems: 'center' }}>
              <UitkCheckbox
                key=""
                label=""
                value=""
                name="checkboxGroup"
                checked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
              >
                I accept the Terms and Conditions.
              </UitkCheckbox>
            </div>
            <div style={{ marginTop: '10px' }}>
              <UitkPrimaryButton
                onClick={handleAccept}
                disabled={!isChecked}
                style={{ width: '100%', marginBottom: '10px' }}
              >
                Accept
              </UitkPrimaryButton>
              <UitkButton onClick={handleLogout} style={{ width: '100%', background: 'none' }}>
                Log out
              </UitkButton>
            </div>
          </div>
        </UitkDialogContent>
      </UitkDialog>
    </div>
  );
};

export default TermsAndConditions;
