import React, { SetStateAction, useEffect, useRef } from 'react';

import Banner from '@common/banner';
import PageError from '@common/error-page';
import { useLayoutHelper } from '@hooks/use-layout-helper';
import { useStores } from '@hooks/use-stores';
import { SideNavBar } from '@layout/layout-content/side-nav-bar';
import { useDialog } from '@shared-ui/dialog-context';
import { observer } from 'mobx-react';
import { ErrorBoundary } from 'react-error-boundary';
import { UitkCard, UitkCardContentSection } from 'uitk-react-cards';
import { UitkDialog, UitkDialogContent } from 'uitk-react-dialog';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkLoader } from 'uitk-react-loader';
import { UitkLineSkeleton } from 'uitk-react-skeleton';
import { UitkParagraph, UitkHeading } from 'uitk-react-text';

interface IProps {
  navOpen: boolean;
  setNavOpen: React.Dispatch<SetStateAction<boolean>>;
  children: React.ReactNode;
  ignorePadding?: boolean;
}

export const ViewContainer: React.FC<IProps> = observer(({ navOpen, setNavOpen, children, ignorePadding }: IProps) => {
  const { user, layout, banner } = useStores();
  const { isMobile } = useLayoutHelper();
  const dialogTriggerRef = useRef();
  const [isDialogOpen, dialogActions, DialogComponent] = useDialog('loading-overlay');

  let navContainerClass = `nav-container`;
  if (isMobile) {
    navContainerClass += ` nav-container-mobile`;
  }
  if (navOpen) {
    navContainerClass += ' nav-open';
  }

  const closeNavIfMobile = () => {
    if (isMobile) {
      setNavOpen(false);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      fetch('/session-check')
        .then((r) => r.text())
        .then((r) => {
          if (r == 'false') {
            window.location.reload();
          }
        });
    }, 1000 * 60);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (layout.loadingOverlay) {
      dialogActions.openDialog();
    } else {
      dialogActions.closeDialog();
    }
  }, [layout.loadingOverlay]);

  return (
    <div className={navContainerClass}>
      <DialogComponent>
        <UitkDialog
          ariaLabel="overlay-loader"
          returnFocusOnClose={true}
          dialogShow={isDialogOpen}
          triggerRef={dialogTriggerRef}
        >
          <DialogLoader header={layout.loadingOverlayHeader} body={layout.loadingOverlayBody} />
        </UitkDialog>
      </DialogComponent>
      <SideNavBar navOpen={navOpen} setNavOpen={setNavOpen} />
      <main role="main" className="page-container">
        {banner && <Banner />}
        <span role="presentation" className="nav-drawer-mask" onClick={closeNavIfMobile} />
        <div>
          <div style={ignorePadding ? {} : { padding: '1.5rem' }}>
            <UitkLayoutFlex space="six" direction="column">
              <UitkLayoutFlexItem style={{ flexGrow: 10 }}>
                <div>
                  {(!user || !user.actingUserId) && (
                    <PageError
                      error={Error('Something went wrong attempting to load user data')}
                      resetErrorBoundary={null}
                    />
                  )}
                  {layout.loadingMainPageBody && (
                    <>
                      <PageLoader />
                    </>
                  )}
                  {user && user.actingUserId && <ErrorBoundary FallbackComponent={PageError}>{children}</ErrorBoundary>}
                </div>
              </UitkLayoutFlexItem>
            </UitkLayoutFlex>
          </div>
        </div>
      </main>
    </div>
  );
});

interface IDialogLoaderProps {
  header?: string;
  body?: string;
}

const DialogLoader: React.FC<IDialogLoaderProps> = ({ header, body }: IDialogLoaderProps) => {
  return (
    <UitkDialogContent key="dialogContent2">
      <UitkLayoutFlex space="two" direction="column">
        <>
          <UitkLayoutFlexItem alignSelf="center">
            <UitkLoader />
          </UitkLayoutFlexItem>
          {header && (
            <UitkLayoutFlexItem alignSelf="center">
              <UitkHeading tag="h2" size={5} key="dialogHeading">
                {header}
              </UitkHeading>
            </UitkLayoutFlexItem>
          )}
          {body && (
            <UitkLayoutFlexItem alignSelf="center">
              <UitkParagraph key="dialogParagraph" size={2}>
                {body}
              </UitkParagraph>
            </UitkLayoutFlexItem>
          )}
        </>
      </UitkLayoutFlex>
    </UitkDialogContent>
  );
};

const PageLoader = () => {
  return (
    <UitkCard padded={false}>
      <UitkCardContentSection>
        <UitkLineSkeleton />
      </UitkCardContentSection>
      <UitkCardContentSection>
        <UitkLineSkeleton animation={true} type="secondary" />
      </UitkCardContentSection>
    </UitkCard>
  );
};
