import axios from 'axios';

import { CDN_URL } from '../constants';

export const fetchLocalStorageTermsAndConditionsAccepted = () => {
  return localStorage.getItem('capTermsAndConditionsAccepted');
};

export const fetchTermsConditionsMetadata = async () => {
  try {
    const cdnUrl = CDN_URL;
    const response = await axios.get(cdnUrl + '/terms-and-conditions/metadata.json');
    return response.data;
  } catch (error) {
    console.error('Error fetching metadata:', error);
    throw error;
  }
};
