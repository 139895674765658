import React from 'react';

const LayoutContext = React.createContext({
  collapse: false,
});

export const LayoutContextProvider = ({ bounds, children }) => {
  const collapse = bounds.width < 991;

  return <LayoutContext.Provider value={{ collapse: collapse }}>{children}</LayoutContext.Provider>;
};

export const useLayoutContext = () => {
  const context = React.useContext(LayoutContext);
  if (!context) {
    throw new Error('useLayoutContext must be used within a LayoutContextProvider');
  }
  return context;
};
