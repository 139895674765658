import * as React from 'react';

import ControlPlaneGraphProvider from '@graphql/graph-providers';
import { useStores } from '@hooks/use-stores';
import { AccountSelectionCard } from '@shared-ui/control-plane-eg-console-account-selection-card';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';

const SelectAccount: React.FC<RouteComponentProps> = observer(() => {
  const { config } = useStores();

  return (
    <ControlPlaneGraphProvider>
      <AccountSelectionCard
        inputs={{ selectionUrl: `${config.appConfig.appUrl}/assume`, signupUrl: '/onboarding?apid=create' }}
      />
    </ControlPlaneGraphProvider>
  );
});

SelectAccount.displayName = 'Select Account';

export default SelectAccount;
