import React from 'react';

import { FallbackProps } from 'react-error-boundary';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkView } from 'uitk-react-view';

import { ErrorCard } from './error-card';

interface IProps extends FallbackProps {}

const ErrorPage = ({ error }: IProps) => {
  return (
    <main role="main" className="page-container">
      <UitkView className="page-container">
        <UitkLayoutFlex alignItems="center" justifyContent="center">
          <UitkLayoutFlexItem alignSelf="center">
            <ErrorCard error={error} theme="secondary" />
          </UitkLayoutFlexItem>
        </UitkLayoutFlex>
      </UitkView>
    </main>
  );
};

export default ErrorPage;
