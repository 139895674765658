import * as React from 'react';

import { UitkBreadcrumbs } from '@egds/react-core';
import { SubSubCategory } from 'src/common/__generated__/api/types';
import { UitkTertiaryButton } from 'uitk-react-button';
import { UitkCard } from 'uitk-react-cards';
import { UitkIcon } from 'uitk-react-icons';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkText } from 'uitk-react-text';

import { ContactForm } from './contact-form';

interface IIntakeFormProps {
  data: SubSubCategory;
  parentCategory: string;
  subCategory: string;
  question: string;
  onBack?: () => void;
  renderToast: (successMsg: string) => void;
}

const IntakeForm: React.FC<IIntakeFormProps> = ({
  data,
  onBack,
  parentCategory,
  subCategory,
  question,
  renderToast,
}) => {
  const crumbs = [
    <UitkText key={parentCategory} inline={true} className={'inline-padding'}>
      {parentCategory}
    </UitkText>,
    <UitkText key={subCategory} inline={true}>
      {subCategory}
    </UitkText>,
    <UitkText key={question} inline={true}>
      {question}
    </UitkText>,
  ];

  return (
    <UitkCard padded>
      <UitkSpacing>
        <UitkTertiaryButton onClick={onBack}>
          <UitkIcon name={data.backIcon.token} />
          {data.backButton.primary}
        </UitkTertiaryButton>
      </UitkSpacing>
      <div style={{ padding: '0px 0px 4px 2px' }}>
        <UitkBreadcrumbs crumbs={crumbs} hasDynamicInlineCrumbs={true} />
      </div>
      <div style={{ padding: '0px 12px' }}>
        <UitkLayoutFlex direction={'column'} alignItems={'start'} space={'two'}>
          <UitkLayoutFlexItem>
            <UitkText size={400} weight={'bold'}>
              {data.contactForm.secondaryTitle}
            </UitkText>
          </UitkLayoutFlexItem>
          <UitkLayoutFlexItem>
            <UitkText>{data.contactForm.secondarySubtitle}</UitkText>
          </UitkLayoutFlexItem>
        </UitkLayoutFlex>
      </div>
      <ContactForm
        data={data}
        parentCategory={parentCategory}
        subCategory={subCategory}
        question={question}
        onBack={onBack}
        handleCancel={onBack}
        renderToast={renderToast}
      />
    </UitkCard>
  );
};

IntakeForm.displayName = 'Intake form';
export { IntakeForm };
