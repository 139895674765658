import React from 'react';

import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { useStores } from '@hooks/use-stores';

const ControlPlaneGraphProvider = ({ children }) => {
  const { config } = useStores();
  const { url } = config.appConfig.controlPlaneGraph;
  const client = React.useMemo(
    () =>
      new ApolloClient({
        credentials: 'same-origin',
        cache: new InMemoryCache({
          typePolicies: {
            Query: {
              fields: {
                consolePayments: {
                  merge(existing, incoming) {
                    return { ...existing, ...incoming };
                  },
                },
              },
            },
          },
          possibleTypes: {
            PartnerPaymentsButton: ['UIPrimaryButton', 'UISecondaryButton'],
          },
        }),
        uri: url,
        name: `advertiser-portal-pwa`,
      }),
    [url],
  );
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ControlPlaneGraphProvider;
