import { useCallback, useEffect, useState } from 'react';

const MOBILE_BREAK_POINT = 960;

function useLayoutHelper() {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [navOpen, setNavOpen] = useState<boolean>(true);

  const callback = useCallback((e: Event) => {
    if (e && window) {
      const target = e.target as Window;
      if (target.innerWidth < MOBILE_BREAK_POINT) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
  }, []);

  useEffect(() => {
    if (window) {
      setNavOpen(window.innerWidth > MOBILE_BREAK_POINT);
      setIsMobile(window.innerWidth < MOBILE_BREAK_POINT);
      window.addEventListener('resize', callback);
      return () => window.removeEventListener('resize', callback);
    }
  }, [callback]);

  useEffect(() => {
    if (isMobile) {
      setNavOpen(false);
    } else {
      setNavOpen(true);
    }
  }, [isMobile]);

  return {
    isMobile: isMobile,
    navOpen: navOpen,
    setNavOpen: setNavOpen,
  };
}

export { useLayoutHelper };
