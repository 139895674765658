import moment from 'moment/moment';

import { DateInput } from '../common/__generated__/api/types';

export const isObjectEmpty = (obj: Record<string, unknown>): boolean => {
  return Object.values(obj).every((x) => x === null || x === '');
};

export const formatDate = (date): DateInput => {
  const utcDate = moment(date).utc();
  return {
    day: Number(utcDate.format('DD')),
    month: Number(utcDate.format('MM')),
    year: Number(utcDate.format('YYYY')),
  };
};
