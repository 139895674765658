import * as React from 'react';

import { UitkBadgeStandard } from 'uitk-react-badge';
import { UitkCard } from 'uitk-react-cards';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkHeading, UitkText } from 'uitk-react-text';

const HowCampaignGoalsWork = () => {
  return (
    <UitkLayoutFlex direction="column" space="four">
      <UitkText>
        Campaign goals help you automatically optimize your performance for specific metrics and keep your campaign
        competitive.
      </UitkText>
      <UitkText>
        Consider what outcome you want your advertising to deliver, and align your campaign goal with your main business
        objective. You can always update your campaign goal later.
      </UitkText>
      <UitkCard border={true} padded>
        <UitkLayoutFlex direction="column">
          <UitkLayoutFlexItem>
            <UitkSpacing margin={{ blockstart: 'two' }} padding={{ inline: 'four' }}>
              <UitkHeading>Maximize clicks</UitkHeading>
            </UitkSpacing>
          </UitkLayoutFlexItem>
          <UitkLayoutFlexItem>
            <UitkSpacing margin={{ blockstart: 'two' }} padding={{ inline: 'four' }}>
              <UitkText>
                Automatically optimize your bids to maximize clicks and keep your campaign competitive based on
                fluctuating market conditions.
              </UitkText>
            </UitkSpacing>
          </UitkLayoutFlexItem>
        </UitkLayoutFlex>
      </UitkCard>
      <UitkCard border={true} padded>
        <UitkLayoutFlex direction="column" justifyContent="space-between">
          <UitkLayoutFlex justifyContent="space-between">
            <UitkSpacing margin={{ blockstart: 'two' }} padding={{ inline: 'four' }}>
              <UitkHeading>Maximize ROAS</UitkHeading>
            </UitkSpacing>
            <UitkBadgeStandard theme="info">Coming Soon</UitkBadgeStandard>
          </UitkLayoutFlex>
          <UitkLayoutFlexItem>
            <UitkSpacing margin={{ blockstart: 'two' }} padding={{ inline: 'four' }}>
              <UitkText theme="secondary">
                Automatically optimize your bids to big more selectively and target a specific return on ad spend
                (ROAS).
              </UitkText>
            </UitkSpacing>
          </UitkLayoutFlexItem>
        </UitkLayoutFlex>
      </UitkCard>
    </UitkLayoutFlex>
  );
};

export default HowCampaignGoalsWork;
