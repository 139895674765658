import * as React from 'react';

import { gql } from '@apollo/client';
import { HowToArticle } from '@components/helpAndSupport/fragments/how-to-article';
import { UitkActionList, UitkActionListItem, UitkExpandoPeek } from '@egds/react-core';
import { FragmentComponent } from '@graphql/component-types';
import { UitkCard, UitkCardContentSection } from 'uitk-react-cards';
import { UitkHeading, UitkText } from 'uitk-react-text';

import { SubCategory, SubSubCategory } from '../../../common/__generated__/api/types';

const fragment = gql`
  fragment subCategoryFragment on SubCategory {
    name
    expand {
      expandedLabel
      collapsedLabel
      expanded
    }
    subSubcategory {
      ...howToArticleFragment
    }
  }
  ${HowToArticle.fragment}
`;

interface ISubCategoryProps {
  data: SubCategory;
  onSubSubCategorySelect: (subSubCategory: SubSubCategory, subCategory: SubCategory) => void;
}
export const SubCategoryCard: FragmentComponent<ISubCategoryProps, SubCategory> = ({
  data,
  onSubSubCategorySelect,
}) => {
  const [visible, setVisible] = React.useState(false);
  const handleSubSubCatClick = (subSubCat: SubSubCategory) => {
    onSubSubCategorySelect(subSubCat, data);
  };
  const handleClick = () => {
    setVisible(!visible);
  };

  return (
    <>
      <UitkCard padded style={{ width: '49.5%', padding: '24px 24px' }}>
        <UitkCardContentSection padded>
          <UitkHeading size={5}>{data.name}</UitkHeading>
          <UitkActionList>
            {data?.subSubcategory?.slice(0, 2).map((subSubCat: SubSubCategory) => (
              <UitkActionListItem key={subSubCat.question}>
                <UitkText size={400} weight="regular" theme="standard" align="left">
                  <button
                    style={{ backgroundColor: 'transparent', textAlign: 'left' }}
                    onClick={() => handleSubSubCatClick(subSubCat)}
                  >
                    {subSubCat.question}
                  </button>
                </UitkText>
              </UitkActionListItem>
            ))}
            {data?.subSubcategory?.length > 2 && (
              <UitkExpandoPeek
                expandLabel={data.expand.expandedLabel}
                collapseLabel={data.expand.collapsedLabel}
                isVisible={visible}
                lines={2}
                handleClick={handleClick}
                className="help-support-expando-peek"
              >
                <UitkActionList>
                  {data.subSubcategory.slice(2).map((subSubCat: SubSubCategory) => (
                    <UitkActionListItem key={subSubCat.question} className="help-support-expando-peek-list-item">
                      <UitkText size={400} weight="regular" theme="standard">
                        <button
                          style={{ backgroundColor: 'transparent' }}
                          onClick={() => handleSubSubCatClick(subSubCat)}
                        >
                          {subSubCat.question}
                        </button>
                      </UitkText>
                    </UitkActionListItem>
                  ))}
                </UitkActionList>
              </UitkExpandoPeek>
            )}
          </UitkActionList>
        </UitkCardContentSection>
      </UitkCard>
    </>
  );
};
SubCategoryCard.fragment = fragment;
SubCategoryCard.displayName = 'Sub category Card';
