import React from 'react';

import { UitkCard, UitkCardContentSection } from 'uitk-react-cards';
import { UitkLineSkeleton } from 'uitk-react-skeleton';

const LoadingSkeletonCard: React.FC = () => {
  return (
    <UitkCard padded={false}>
      <UitkCardContentSection>
        <UitkLineSkeleton />
      </UitkCardContentSection>
      <UitkCardContentSection>
        <UitkLineSkeleton animation={true} type="secondary" />
      </UitkCardContentSection>
    </UitkCard>
  );
};

export default LoadingSkeletonCard;
