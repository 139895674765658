import * as React from 'react';

import { gql } from '@apollo/client';
import { UitkBreadcrumbs } from '@egds/react-core';
import { FragmentComponent } from '@graphql/component-types';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { UitkButtonSize, UitkTertiaryButton } from 'uitk-react-button';
import { UitkCard, UitkCardContentSection } from 'uitk-react-cards';
import { UitkIcon, UitkIconSize } from 'uitk-react-icons';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkToast } from 'uitk-react-toast';

import { SubSubCategory } from '../../../common/__generated__/api/types';
import { ContactForm } from '../internal/contact-form';

export const howToArticleFragment = gql`
  fragment howToArticleFragment on SubSubCategory {
    question
    answer
    backButton {
      primary
    }
    backIcon {
      description
      id
      token
    }
    contactForm {
      title
      secondaryTitle
      subtitle
      secondarySubtitle
      articleMessageButton {
        primary
      }
      firstName {
        label
        placeholder
        validations {
          ... on EGDSMinLengthInputValidation {
            minLength
            errorMessage
          }
        }
      }
      lastName {
        label
        placeholder
        validations {
          ... on EGDSMinLengthInputValidation {
            minLength
            errorMessage
          }
        }
      }
      email {
        label
        placeholder
        validations {
          ... on EGDSRegexInputValidation {
            pattern
            errorMessage
          }
        }
      }
      suppliedEmail {
        label
        placeholder
        validations {
          ... on EGDSRegexInputValidation {
            pattern
            errorMessage
          }
        }
      }
      message {
        label
        placeholder
        validations {
          ... on EGDSMinLengthInputValidation {
            minLength
            errorMessage
          }
        }
      }
      contactIcon {
        description
        id
        token
      }
      sendButton {
        primary
      }
      cancelButton {
        primary
      }
    }
  }
`;

interface ISubSubCategoryProps {
  data: SubSubCategory;
  parentCategory: string;
  subCategory: string;
  onBack: () => void;
}
export const HowToArticle: FragmentComponent<ISubSubCategoryProps, SubSubCategory> = ({
  data,
  parentCategory,
  subCategory,
  onBack,
}) => {
  const crumbs = [
    <UitkText key={parentCategory} inline={true} className={'inline-padding'}>
      {parentCategory}
    </UitkText>,
    <UitkText key={subCategory} inline={true}>
      {subCategory}
    </UitkText>,
    <UitkText key={data.question} inline={true}>
      {data.question}
    </UitkText>,
  ];

  const [showContactForm, setShowContactForm] = React.useState(false);
  const [contactFormSubmitted, setContactFormSubmitted] = React.useState(false);

  const handleSuccess = (mutationState) => {
    setContactFormSubmitted(mutationState);
  };

  const handleCancel = () => {
    setShowContactForm(false);
  };

  const [show, setShow] = React.useState(false);
  const onCloseRef = React.useRef(null);
  const [successResponse, setSuccessResponse] = React.useState('');

  const renderToast = (successMsg) => {
    setShow(true);
    setSuccessResponse(successMsg);
  };

  React.useEffect(() => {
    if (show) {
      setTimeout(() => {
        setShow(false);
      }, 4000);
    }
  }, [show]);

  const contactForm = (
    <div style={{ marginBlockStart: '16px' }}>
      <UitkCard padded>
        <UitkCardContentSection>
          <UitkLayoutFlex space={'three'} blockSize={'full_size'} alignItems={'center'}>
            <UitkLayoutFlexItem>
              <UitkIcon name={data.contactForm.contactIcon.token} />
            </UitkLayoutFlexItem>
            <UitkLayoutFlex direction={'column'} alignItems={'start'}>
              <UitkLayoutFlexItem>
                <UitkText weight={'bold'}>{data.contactForm.title}</UitkText>
              </UitkLayoutFlexItem>
              <UitkSpacing padding={{ blockstart: 'one' }}>
                <UitkLayoutFlexItem>
                  <UitkText>{data.contactForm.subtitle}</UitkText>
                </UitkLayoutFlexItem>
              </UitkSpacing>
            </UitkLayoutFlex>
          </UitkLayoutFlex>
        </UitkCardContentSection>
        <ContactForm
          data={data}
          parentCategory={parentCategory}
          subCategory={subCategory}
          question={data.question}
          onBack={onBack}
          handleCancel={handleCancel}
          handleSuccess={handleSuccess}
          renderToast={renderToast}
        />
      </UitkCard>
    </div>
  );

  const needMoreHelpCard = (
    <UitkSpacing margin={{ blockstart: 'four' }}>
      <UitkCard padded style={{ padding: '24px 24px' }}>
        <UitkCardContentSection padded>
          <UitkLayoutFlex space={'three'} blockSize={'full_size'} alignItems={'center'}>
            <UitkLayoutFlexItem>
              <UitkIcon name={data.contactForm.contactIcon.token} size={UitkIconSize.LARGE} />
            </UitkLayoutFlexItem>
            <UitkLayoutFlex direction={'column'} alignItems={'start'} space={'three'}>
              <UitkLayoutFlexItem minWidth={'1x'}>
                <UitkText size={400} weight={'bold'}>
                  {data.contactForm.title}
                </UitkText>
              </UitkLayoutFlexItem>
              <UitkLayoutFlexItem>
                <UitkText>{data.contactForm.subtitle}</UitkText>
              </UitkLayoutFlexItem>
              <UitkLayoutFlexItem>
                <UitkTertiaryButton
                  alignLabelToContent="start"
                  size={UitkButtonSize.SMALL}
                  className="need-more-help-button"
                  onClick={() => setShowContactForm(true)}
                >
                  {data.contactForm.articleMessageButton.primary}
                </UitkTertiaryButton>
              </UitkLayoutFlexItem>
            </UitkLayoutFlex>
          </UitkLayoutFlex>
        </UitkCardContentSection>
      </UitkCard>
    </UitkSpacing>
  );

  return (
    <>
      <UitkCard padded>
        <UitkToast onCloseRef={onCloseRef} show={show} autoHide={false}>
          {successResponse}
        </UitkToast>
        <UitkCardContentSection padded>
          <UitkTertiaryButton onClick={onBack}>
            <UitkIcon name={data.backIcon.token} />
            <UitkText size={500}>{data.backButton.primary}</UitkText>
          </UitkTertiaryButton>
          <UitkBreadcrumbs crumbs={crumbs} hasDynamicInlineCrumbs={true} />
          <div style={{ padding: '0px 12px 12px 12px' }}>
            <UitkSpacing margin={{ blockend: 'four' }}>
              <UitkHeading size={4}>{data.question}</UitkHeading>
            </UitkSpacing>
            <UitkSpacing margin={{ blockstart: 'two' }}>
              <ReactMarkdown
                className="markdown-content"
                rehypePlugins={[rehypeRaw]}
                components={{
                  a: ({ ...props }) => (
                    <a className="uitk-link" style={{ color: '#1668e3' }} target="_blank" {...props} />
                  ),
                  br: () => <div style={{ marginBottom: '8px' }}></div>,
                }}
              >
                {data.answer}
              </ReactMarkdown>
            </UitkSpacing>
          </div>
        </UitkCardContentSection>
      </UitkCard>
      {!showContactForm ? needMoreHelpCard : null}
      {showContactForm === true && contactFormSubmitted === false ? contactForm : null}
    </>
  );
};
HowToArticle.fragment = howToArticleFragment;
HowToArticle.displayName = 'How to article';
