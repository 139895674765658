import { gql } from '@apollo/client';

export const ADVERTISER_REPORTING_FORM = gql`
  query AdvertiserReportingForm($reportingContext: AdvertiserReportingContext!) {
    advertiserReportingForm(reportingContext: $reportingContext) {
      downloadButton {
        title
        dialog {
          toolbar {
            title
          }
        }
        dialogButtons {
          text
          image {
            altText
            description
            url
          }
        }
        triggerButton {
          disabled
          egdsElementId
          icon {
            description
            id
            theme
            title
            token
          }
        }
      }
      filterOptions {
        filters {
          ... on ReportSelectMenu {
            id
            select {
              options {
                label
                value
                selected
              }
              label
            }
            defaultText
          }
          ... on ReportPillMenu {
            menu {
              disabled
              title
              subMenus {
                title
                items {
                  title
                  ... on EGDSMenuTextItem {
                    title
                  }
                }
              }
            }
            pill {
              disabled
              name
              primary
              selected
              value
            }
            defaultText
          }
          ... on ReportFilterMenu {
            typeAheadSelectionOptions {
              id
              name
            }
            closeIcon {
              description
              id
              title
              token
            }
            doneButton {
              disabled
              egdsElementId
              primary
            }
            pill {
              disabled
              name
              primary
              selected
              value
            }
            defaultText
          }
        }
        filterText
        clearAllButton {
          disabled
          name
          primary
          selected
          value
        }
      }
      filtersAppliedToggle {
        collapsedLabel
        expanded
        expandedLabel
      }
      header {
        primaryText
        secondaryText
      }
      loadingText {
        text
        weight
      }
      currencyInputField {
        label
        leftIcon {
          id
          token
        }
        readOnly
      }
      refreshButton {
        disabled
        egdsElementId
        primary
      }
    }
  }
`;

export const ADVERTISER_REPORTING_TABLE = gql`
  query AdvertiserReportingTable(
    $reportingContext: AdvertiserReportingContext!
    $reportingOptions: AdvertiserReportingOptionsInput!
  ) {
    advertiserReportingTable(reportingContext: $reportingContext, reportingOptions: $reportingOptions) {
      striped
      currency {
        primaryText
        currencyCode
        icon
      }
      campaignInfoMap {
        id
        endDate
        startDate
        list
      }
      regionMultiSelectOptions {
        section
        options {
          id
          name
        }
      }
      campaignMultiSelectOptions {
        section
        options {
          id
          name
        }
      }
      adGroupMultiSelectOptions {
        section
        options {
          id
          campaignId
          name
          campaignName
        }
      }
      adMultiSelectOptions {
        section
        options {
          id
          campaignId
          adgroupId
          name
          campaignName
          adgroupName
        }
      }
      lastSort {
        sortBy
        sortDirection
      }
      headers {
        text {
          text
          weight
        }
        sortable
        border
        width
        sortIcon {
          token
        }
      }
      noResultsSection {
        primaryText {
          text
          weight
        }
        secondaryText {
          text
        }
        noResultsIcon {
          id
          token
        }
      }
      totalsRow {
        backgroundColor
        content {
          ... on AdvertiserReportingNameCell {
            border
            text {
              text
              weight
            }
          }
          ... on AdvertiserReportingTextCell {
            text {
              text
              weight
            }
          }
          ... on AdvertiserReportingBadgeCell {
            badge {
              text
              theme
            }
          }
          ... on AdvertiserReportingNumberCell {
            amount
            amountDouble
            weight
            formattedAmount
          }
        }
      }
      reportingData {
        row {
          backgroundColor
          content {
            ... on AdvertiserReportingNameCell {
              striped
              colSpan
              align
              padding
              border
              text {
                text
                weight
              }
              icon {
                id
                token
              }
            }
            ... on AdvertiserReportingTextCell {
              text {
                text
                weight
              }
            }
            ... on AdvertiserReportingBadgeCell {
              badge {
                text
                theme
              }
            }
            ... on AdvertiserReportingNumberCell {
              amount
              amountDouble
              weight
              formattedAmount
            }
          }
        }
      }
    }
  }
`;

export const AD_COPY_REVIEW_TABLE = gql`
  query AdCopyReviewTable {
    adCopyReviewTable(
      productContext: LODGING_SPONSORED_LISTING
      request: {
        partnerAccountNameSearch: null
        startDate: { day: 1, month: 1, year: 2024 }
        endDate: { day: 1, month: 1, year: 2024 }
        language: null
        resultsPerPage: 100
        sortBy: null
        sortDirection: null
      }
    ) {
      noResultsText
      unreviewedAdCopyTabName
      headers {
        submissionDate {
          text
          width
          sortIcon {
            id
            token
            description
          }
        }
        partnerAccount {
          text
          width
          sortIcon {
            id
            token
            description
          }
        }
        language {
          text
          width
          sortIcon {
            id
            token
            description
          }
        }
        adCopy {
          text
          width
          sortIcon {
            id
            token
            description
          }
        }
        actions {
          text
          width
          sortIcon {
            id
            token
            description
          }
        }
      }
      data {
        contentId
        submissionDate
        partnerAccount {
          partnerName
          partnerId
        }
        language
        adCopy {
          ... on AdCopyReviewTableLodgingCreativeAdCopy {
            headline
            description
          }
        }
        actionIcon {
          id
          token
          description
        }
        actionNames
      }
    }
  }
`;

export const AD_COPY_REVIEW_FORM = gql`
  query AdCopyReviewForm {
    adCopyReviewForm(productContext: LODGING_SPONSORED_LISTING) {
      pageTitle
      loadingText {
        text
        weight
      }
    }
  }
`;

export const PRODUCT_SELECT_QUERY = gql`
  query productCard(
    $partnerAccountId: String
    $productContext: ProductContext!
    $pageType: AdvertiserPortalPageInput
    $productIds: [String!]
  ) {
    advertiserPortalProductSelection(
      partnerAccountId: $partnerAccountId
      productContext: $productContext
      pageType: $pageType
      productIds: $productIds
    ) {
      title
      description
      associatedProductsSubtitle
      addProductsSubtitle
      products {
        id
        name
      }
      content {
        ... on AdvertiserPortalPropertySelectionCard {
          addPropertiesTypeahead {
            egdsElementId
            label
            placeholder
            leftIcon {
              token
            }
            typeaheadInfo {
              client
              isDestination
              domain
              typeaheadFeatures
              lineOfBusiness
              regionType
              personalize
              allowExactMatch
              maxNumberOfResults
            }
          }
          propertyIcon {
            token
          }
          deleteIcon {
            token
          }
          locale
        }
      }
    }
  }
`;

export const AD_COPY_VALIDATION = gql`
  query AdCopyValidation($productContext: ProductContext!, $request: AdCopyValidationInput!) {
    adCopyValidation(productContext: $productContext, request: $request) {
      adCopyValidationContentChecks {
        adId
        headlineChecks {
          checksPassed
          rejectionReasons
        }
        descriptionChecks {
          checksPassed
          rejectionReasons
        }
      }
    }
  }
`;
