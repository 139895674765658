import * as React from 'react';

import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkText, UitkHeading } from 'uitk-react-text';

const PaymentProfileInfo = () => {
  return (
    <UitkLayoutFlex direction="column" space="four">
      <UitkHeading>What is a Payment profile?</UitkHeading>
      <UitkText>
        In the Payment profile you can specify the billing address and the preferred currency for invoices and campaign
        budget.
      </UitkText>
      <UitkHeading>Selecting a currency</UitkHeading>
      <UitkText>The currency you choose will be applied to your campaign budget and your invoices.</UitkText>
    </UitkLayoutFlex>
  );
};

export default PaymentProfileInfo;
