import * as React from 'react';

import { FetchResult, useMutation } from '@apollo/client';
import { CREATE_SALESFORCE_CASE_MUTATION } from '@graphql/mutations';
import { useStores } from '@hooks/use-stores';
import { SalesforceCaseErrorResponse, SalesforceCaseInputModelInput } from 'src/common/__generated__/api/types';
import { UitkBanner } from 'uitk-react-banner';
import { UitkPrimaryButton, UitkSecondaryButton } from 'uitk-react-button';
import { UitkCardContentSection } from 'uitk-react-cards';
import { UitkIcon } from 'uitk-react-icons';
import { UitkTextArea, UitkTextInput } from 'uitk-react-input';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkLayoutGrid, UitkLayoutGridItem } from 'uitk-react-layout-grid';
import { UitkHeading, UitkText } from 'uitk-react-text';

import { Mutation, SubSubCategory, EgdsRegexInputValidation } from '../../../common/__generated__/api/types';

interface IContactFormProps {
  data: SubSubCategory;
  parentCategory: string;
  subCategory: string;
  question: string;
  onBack?: () => void;
  handleCancel?: () => void;
  handleSuccess?: (mutationState: boolean) => void;
  renderToast?: (successMsg: string) => void;
}

const ContactForm: React.FC<IContactFormProps> = ({
  data,
  parentCategory,
  subCategory,
  question,
  handleCancel,
  handleSuccess,
  renderToast,
}) => {
  const { user } = useStores();

  const defaultContactForm: SalesforceCaseInputModelInput = {
    suppliedFirstName: user.name.split(' ')[0],
    suppliedLastName: user.name.split(' ')[1],
    suppliedEmail: user.primaryEmail,
    ccEmail: '',
    description: '',
    partnerAccountId: String(user.partnerAccountId),
    egUserId: user.userId,
    subject: `${parentCategory}/${subCategory}/${question}`,
    partnerAccountName: user.partnerName,
  };

  const [createSalesforceCaseMutation, { loading }] = useMutation(CREATE_SALESFORCE_CASE_MUTATION);

  const [successResponse, setSuccessResponse] = React.useState('');
  const [errorResponse, setErrorResponse] = React.useState<SalesforceCaseErrorResponse>();

  const [validMessage, setValidMessage] = React.useState(true);
  const [validFirstName, setValidFirstName] = React.useState(true);
  const [validLastName, setValidLastName] = React.useState(true);
  const [validEmail, setValidEmail] = React.useState(true);
  const [validCCEmail, setValidCCEmail] = React.useState(true);
  const [showError, setShowError] = React.useState(false);

  const createSalesforceCase = async () => {
    const isMessageValid = validateMessage();
    if (!isMessageValid) {
      setShowError(true);
      return;
    }

    const response = await createSalesforceCaseMutation({
      variables: {
        inputForm: {
          ...contactForm,
        },
      },
    });
    handleCreateSalesforceCaseResponse(response);
  };

  const handleCreateSalesforceCaseResponse = async (response: FetchResult<Mutation>) => {
    if (response.errors || response.data.createSalesforceCase.errorResponse) {
      setErrorResponse(response.data.createSalesforceCase.errorResponse);
      console.error('Error in createSalesforceCase response', response.errors);
    } else {
      setSuccessResponse(response.data.createSalesforceCase.successResponse.message);
      setErrorResponse(null);
      if (handleSuccess) {
        handleSuccess(true);
      }
    }
  };

  React.useEffect(() => {
    if (successResponse) {
      renderToast(successResponse);
    }
  }, [successResponse]);

  const [contactForm, setContactForm] = React.useState<SalesforceCaseInputModelInput>(defaultContactForm);

  const updateContactForm = (contactForm: Partial<SalesforceCaseInputModelInput>) => {
    setContactForm((prevForm) => ({ ...prevForm, ...contactForm }));
    if (contactForm.description !== undefined) {
      setValidMessage(true);
      setShowError(false);
    }
  };

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFirstName = e.target.value;
    updateContactForm({ suppliedFirstName: newFirstName });
  };

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newLastName = e.target.value;
    updateContactForm({ suppliedLastName: newLastName });
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    updateContactForm({ suppliedEmail: newEmail });
  };

  const handleCCEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const ccEmail = e.target.value;
    updateContactForm({ ccEmail: ccEmail });
  };

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newMessage = e.target.value;
    updateContactForm({ description: newMessage });
  };

  const validateFirstName = () => {
    if (contactForm.suppliedFirstName.length === 0) {
      setValidFirstName(false);
    } else {
      setValidFirstName(true);
    }
  };

  const validateLastName = () => {
    if (contactForm.suppliedLastName.length === 0) {
      setValidLastName(false);
    } else {
      setValidLastName(true);
    }
  };

  const validateEmail = () => {
    const emailPattern = data.contactForm.email.validations[0] as EgdsRegexInputValidation;
    const emailRegexCheck = contactForm.suppliedEmail?.match(emailPattern.pattern);
    if (!emailRegexCheck) {
      setValidEmail(false);
    } else {
      setValidEmail(true);
    }
  };

  const validatecCEmail = () => {
    const emailPattern = data.contactForm.suppliedEmail.validations[0] as EgdsRegexInputValidation;
    const emailRegexCheck = contactForm.ccEmail?.match(emailPattern.pattern);
    if (contactForm.ccEmail.length > 0 && contactForm.ccEmail !== defaultContactForm.ccEmail && !emailRegexCheck) {
      setValidCCEmail(false);
    } else {
      setValidCCEmail(true);
    }
  };

  const validateMessage = () => {
    if (contactForm.description.length === 0) {
      setValidMessage(false);
      return false;
    } else {
      setValidMessage(true);
      return true;
    }
  };

  const validateError = () => {
    if (contactForm.description.length === 0) {
      setShowError(false);
      return false;
    } else {
      setShowError(true);
      return true;
    }
  };

  React.useEffect(() => {
    validateFirstName();
    validateLastName();
    validateEmail();
    validatecCEmail();
  }, [contactForm.suppliedFirstName, contactForm.suppliedLastName, contactForm.suppliedEmail, contactForm.ccEmail]);

  React.useEffect(() => {
    validateError();
  }, [showError]);

  const isFormValid = validFirstName && validLastName && validEmail && validCCEmail;
  const paddingValue = handleSuccess ? '12px 12px' : '24px 12px 12px 12px';

  return (
    <div>
      {errorResponse && (
        <div style={{ padding: paddingValue, maxWidth: '690px' }}>
          <UitkBanner theme="error">
            <UitkLayoutFlex alignItems="center" space="two">
              <UitkLayoutFlexItem>
                <div>
                  <UitkIcon name="error" spotlight={{ theme: 'standard', background: false }} />
                </div>
              </UitkLayoutFlexItem>
              <UitkLayoutFlexItem>
                <div>
                  <UitkHeading size={7} tag="h2">
                    {errorResponse.message}
                  </UitkHeading>
                  <UitkText>{errorResponse.errorMessage}</UitkText>
                </div>
              </UitkLayoutFlexItem>
            </UitkLayoutFlex>
          </UitkBanner>
        </div>
      )}
      <UitkCardContentSection padded>
        <UitkLayoutGrid space="four" style={{ maxWidth: '668px' }}>
          <UitkLayoutGridItem>
            <UitkLayoutGrid columns={2} space="four">
              <UitkLayoutFlex direction="column">
                <UitkTextInput
                  disabled={loading ?? false}
                  label={data.contactForm.firstName.label}
                  placeholder={data.contactForm.firstName.placeholder}
                  name={data.contactForm.firstName.label}
                  value={contactForm.suppliedFirstName}
                  onChange={handleFirstNameChange}
                  invalid={validFirstName ? null : { primary: data.contactForm.firstName.validations[0].errorMessage }}
                />
              </UitkLayoutFlex>
              <UitkLayoutFlex direction="column">
                <UitkTextInput
                  disabled={loading ?? false}
                  label={data.contactForm.lastName.label}
                  placeholder={data.contactForm.lastName.placeholder}
                  value={contactForm.suppliedLastName}
                  onChange={handleLastNameChange}
                  invalid={validLastName ? null : { primary: data.contactForm.lastName.validations[0].errorMessage }}
                />
              </UitkLayoutFlex>
            </UitkLayoutGrid>
          </UitkLayoutGridItem>
          <UitkLayoutFlex direction="column">
            <UitkTextInput
              disabled={loading ?? false}
              label={data.contactForm.email.label}
              placeholder={data.contactForm.email.placeholder}
              value={contactForm.suppliedEmail}
              onChange={handleEmailChange}
              invalid={validEmail ? null : { primary: data.contactForm.email.validations[0].errorMessage }}
            />
          </UitkLayoutFlex>
          <UitkLayoutFlex direction="column">
            <UitkTextInput
              disabled={loading ?? false}
              label={data.contactForm.suppliedEmail.label}
              placeholder={contactForm.ccEmail}
              value={contactForm.ccEmail}
              onChange={handleCCEmailChange}
              invalid={validCCEmail ? null : { primary: data.contactForm.suppliedEmail.validations[0].errorMessage }}
            />
          </UitkLayoutFlex>
          <UitkLayoutFlex direction="column">
            <UitkTextArea
              disabled={loading ?? false}
              label={data.contactForm.message.label}
              placeholder={data.contactForm.message.placeholder}
              value={contactForm.description}
              onChange={handleMessageChange}
              invalid={validMessage ? null : { primary: data.contactForm.message.validations[0].errorMessage }}
            />
          </UitkLayoutFlex>
          <UitkLayoutFlex justifyContent="end">
            <UitkLayoutGrid columns={['max-content', '82px']} space="four">
              <UitkPrimaryButton onClick={createSalesforceCase} disabled={!isFormValid || loading}>
                {data.contactForm.sendButton.primary}
              </UitkPrimaryButton>
              <UitkSecondaryButton onClick={handleCancel} disabled={loading}>
                {data.contactForm.cancelButton.primary}
              </UitkSecondaryButton>
            </UitkLayoutGrid>
          </UitkLayoutFlex>
        </UitkLayoutGrid>
      </UitkCardContentSection>
    </div>
  );
};

ContactForm.displayName = 'Contact form';
export { ContactForm };
