import React, { SetStateAction } from 'react';

// import { LocalizedComponentClass, withLocalization, LocalizedText } from '@shared-ui/localization-context';
// import { useAppContext } from 'our-context-plugin/dist/context/';
import AccountSwitchOverlay from '@components/viewAndManage/account-switch-overlay';
import { UserPopoverSheet } from '@components/viewAndManage/user-popover-sheet';
import { useLayoutHelper } from '@hooks/use-layout-helper';
import { useStores } from '@hooks/use-stores';
import { useDialog } from '@shared-ui/dialog-context';
import { observer } from 'mobx-react';
import Measure, { ContentRect } from 'react-measure';
import { CDN_URL } from 'src/constants';
import { UitkButton, UitkButtonSize, UitkTertiaryButton } from 'uitk-react-button';
import { GlobalNavigationSiteHeader } from 'uitk-react-global-navigation';
import { UitkHeaderBrandLogo } from 'uitk-react-header-brand-logo';
import { UitkIcon } from 'uitk-react-icons';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkSheetTransition } from 'uitk-react-sheet';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkText } from 'uitk-react-text';

// import LanguageSelect from './language-select/language-select-content';
// import TopNavSearchTooltip from './search-tooltip/top-nav-search-tooltip-content';

interface IProps {
  navOpen: boolean;
  setNavOpen: React.Dispatch<SetStateAction<boolean>>;
}

const TopNavBar: React.FC<IProps> = observer(({ navOpen, setNavOpen }: IProps) => {
  const { user } = useStores();
  const { isMobile } = useLayoutHelper();
  // const [tooltipWidth, setTooltipWidth] = useState(400);
  const triggerRef = React.useRef();
  const [visible, setVisible] = React.useState(false);
  const [isDialogOpen, dialogActions, DialogComponent] = useDialog('switch-account');

  const toggleNav = () => {
    if (navOpen) {
      setNavOpen(false);
    } else {
      setNavOpen(true);
    }
  };

  const handleTooltipResize = (e: ContentRect) => {
    if (e.entry) {
      // setTooltipWidth(e.entry.width);
    }
  };

  //   const idLabel = currentHotel ? currentHotel.hotelId : currentAccount?.accountId;

  const openDialog = () => {
    dialogActions.openDialog();
  };

  const closeDialog = () => {
    dialogActions.closeDialog();
  };

  const toggleVisibility = () => {
    setVisible(() => !visible);
  };

  return (
    <>
      <GlobalNavigationSiteHeader>
        <UitkSpacing padding={{ inline: 'six' }}>
          <UitkLayoutFlex justifyContent="space-between" alignItems="center" className="top-nav-bar">
            <UitkLayoutFlexItem grow={0} className="top-nav-section">
              <div>
                <UitkButton
                  data-testid="hamburger"
                  name="hamburger"
                  className="top-nav-hamburger-btn"
                  onClick={toggleNav}
                >
                  <svg
                    className="nav-icon"
                    width="18"
                    height="12"
                    viewBox="0 0 18 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 12h18v-2H0v2zm0-5h18V5H0v2zm0-7v2h18V0H0z" fill="#FFF" fillRule="evenodd" />
                  </svg>
                </UitkButton>
                <UitkHeaderBrandLogo
                  imgSrc={`${CDN_URL}images/ExpediaGroup-Logo.svg`}
                  href="#"
                  altText="Expedia Group Logo"
                />
              </div>
            </UitkLayoutFlexItem>
            <UitkLayoutFlexItem>
              <Measure onResize={handleTooltipResize}>
                {({ measureRef }) => (
                  <div ref={measureRef}>
                    <UitkLayoutFlex space="two" justifyContent="end">
                      <UitkLayoutFlexItem className="top-nav-section">
                        {!isMobile && (
                          <UitkText>{/* <LocalizedText message="topNavBar.idLabel" data={[idLabel]} /> */}</UitkText>
                        )}
                      </UitkLayoutFlexItem>
                      <UitkLayoutFlexItem className="top-nav-section">
                        {/* {currentAccount && currentHotels && <TopNavSearchTooltip tooltipWidth={tooltipWidth} />} */}
                      </UitkLayoutFlexItem>
                      {user.partnerType == 'FLIGHT_SPONSORED_LISTING' && !user.adminContext && (
                        <UitkLayoutFlexItem className="top-nav-section">
                          <UitkLayoutFlex space="four">
                            <UitkLayoutFlexItem>
                              <p>{user.partnerName}</p>
                            </UitkLayoutFlexItem>
                            <UitkLayoutFlexItem>
                              <UitkIcon name="flight" />
                            </UitkLayoutFlexItem>
                            <UitkLayoutFlexItem>
                              <UitkIcon name="account_circle" />
                            </UitkLayoutFlexItem>
                          </UitkLayoutFlex>
                          {/* <LanguageSelect /> */}
                        </UitkLayoutFlexItem>
                      )}
                      {(user.partnerType == 'LODGING_SPONSORED_LISTING' || user.adminContext) && (
                        <React.Fragment>
                          <UitkLayoutFlexItem>
                            <UitkTertiaryButton
                              inverse
                              size={UitkButtonSize.MEDIUM}
                              href={user.adminContext ? '/admn/help' : '/help'}
                            >
                              <UitkIcon name="help" className="top-nav-section" />
                              {`Help`}
                            </UitkTertiaryButton>
                          </UitkLayoutFlexItem>
                          <UitkLayoutFlexItem>
                            <UserPopoverSheet
                              user={user}
                              visible={visible}
                              toggleVisibility={toggleVisibility}
                              openDialog={openDialog}
                              triggerRef={triggerRef}
                            />
                          </UitkLayoutFlexItem>
                          <UitkSheetTransition isVisible={isDialogOpen}>
                            <DialogComponent>
                              <AccountSwitchOverlay
                                isVisible={isDialogOpen}
                                closeDialog={closeDialog}
                                triggerRef={triggerRef}
                              />
                            </DialogComponent>
                          </UitkSheetTransition>
                        </React.Fragment>
                      )}
                    </UitkLayoutFlex>
                  </div>
                )}
              </Measure>
            </UitkLayoutFlexItem>
          </UitkLayoutFlex>
        </UitkSpacing>
      </GlobalNavigationSiteHeader>
    </>
  );
});

export default TopNavBar;
