import * as React from 'react';

import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkText } from 'uitk-react-text';

const LetUsChooseYourImage = () => {
  return (
    <UitkLayoutFlex direction="column" space="four">
      <UitkText>
        Our machine learning algorithm chooses your best-performing property image based on traveler perferences and
        engagement data. We&apos;ll run ongoing tests to increase the relevancy of your ad images and boost your overall
        ad performance.
      </UitkText>
      <UitkText>
        Advertisers who default to the recommended image historically see an average <b>7% boost</b> in engagement,
        which means greater visibility in the search results and more opportunities for bookings.
      </UitkText>
      <UitkText>Turn off this feature to update your image manually.</UitkText>
    </UitkLayoutFlex>
  );
};

export default LetUsChooseYourImage;
