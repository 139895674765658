import React from 'react';

import SelectAccount from '@views/select-account';
import { UitkSheet, UitkSheetContent } from 'uitk-react-sheet';
import { UitkToolbar } from 'uitk-react-toolbar';

interface AccountSwitchOverlayProps {
  isVisible: boolean;
  closeDialog: () => void;
  triggerRef: React.RefObject<HTMLElement>;
}

const AccountSwitchOverlay: React.FC<AccountSwitchOverlayProps> = ({ isVisible, closeDialog, triggerRef }) => {
  return (
    <UitkSheet type="centered" isVisible={isVisible} triggerRef={triggerRef}>
      <UitkToolbar
        navigationContent={{
          onClick: closeDialog,
          navIconLabel: 'Close Toolbar',
        }}
        toolbarTitle={'Switch account'}
      />
      <UitkSheetContent>
        <SelectAccount history={undefined} location={undefined} match={undefined} />
      </UitkSheetContent>
    </UitkSheet>
  );
};

export default AccountSwitchOverlay;
